import "../scss/main.scss";
import "slick-carousel";

$(function () {
  var adminBar;
  if ($("#wpadminbar")) {
    adminBar = $("#wpadminbar").innerHeight();
  } else {
    adminBar == 0;
  }
  $("main").css("margin-top", $("#masthead").innerHeight());

  $(".bt-search").on("click", function () {
    $(this).toggleClass("open");
    $("#search").toggleClass("opensearch");
  });
  $(".search-close").on("click", function () {
    $("#search").removeClass("opensearch");
  });

  $(".bt-menu").on("click", function () {
    $(this).toggleClass("open");
    $(".col--navigation-mobile").toggleClass("openshop");
  });

  $("#bt-fiche-technique").on("click", function () {
    $("html, body").animate(
      {
        scrollTop:
          $("#ancre-fiche-technique").offset().top -
          $("#masthead").innerHeight() -
          30,
      },
      "slow"
    );
    return false;
  });

  $("#bt-nav-mobile").on("click", function () {
    $(".col--navigation-mobile").removeClass("openshop");
  });

  actionsScroll();
  $(window).on("scroll", function () {
    actionsScroll();
  });

  // INIT ALL FUNCTIONS
  imgBack();
  sliders();
  if ($(".accordeon").length != 0) {
    accordeon();
  }
  if ($(".tabs").length != 0) {
    onglets();
  }
  animationScroll();

  menuImage();
  getParameterFilter();
});

function actionsScroll() {
  $(".site-search").removeClass("active");

  var scrolled = $(window).scrollTop();

  if (scrolled > 0) {
    $("body").addClass("scrolling");
  } else {
    $("body").removeClass("scrolling");
  }
}

function imgBack() {
  $(".backImg").each(function () {
    var urlImgCurrent = $(this).children("img").attr("src");
    if (
      urlImgCurrent &&
      urlImgCurrent != undefined &&
      $(this).children("img").length
    ) {
      $(this).attr(
        "style",
        "background-image:url(" +
          urlImgCurrent +
          "); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;"
      );
      $(this).find("img").remove();
    }
  });
}

function sliders() {
  $(".bloc--slider").not(".slick-initialized").slick({
    autoplay: true,
    autoplaySpeed: 5000,
    respondTo: "slider",
    responsive: true,
    arrows: true,
    dots: true,
    infinite: true,
    centerPadding: "18px",
    prevArrow:
      '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
    nextArrow:
      '<span class="slide-next material-symbols-sharp">navigate_next</span>',
  });

  $(".list-productcat")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      respondTo: "slider",
      responsive: true,
      arrows: true,
      dots: false,
      infinite: true,
      centerPadding: "18px",
      prevArrow:
        '<span class="slide-arrow slide-prev fa-light fa-chevron-left"></span>',
      nextArrow:
        '<span class="slide-arrow slide-next fa-light fa-chevron-right"></span>',
      responsive: [
        {
          breakpoint: 1760,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1330,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1040,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

  $(".related ul.products")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplaySpeed: 2500,
      respondTo: "slider",
      responsive: true,
      arrows: true,
      dots: false,
      infinite: true,
      centerPadding: "18px",
      prevArrow:
        '<span class="slide-arrow slide-prev fa-light fa-chevron-left"></span>',
      nextArrow:
        '<span class="slide-arrow slide-next fa-light fa-chevron-right"></span>',
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

  $(".bloc--equipe .grille-annonce--gallerie")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplaySpeed: 1500,
      respondTo: "slider",
      responsive: true,
      arrows: true,
      dots: false,
      infinite: true,
      centerPadding: "18px",
      prevArrow:
        '<span class="slide-arrow slide-prev fa-light fa-chevron-left"></span>',
      nextArrow:
        '<span class="slide-arrow slide-next fa-light fa-chevron-right"></span>',
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

  $(".bloc--actualites .grille--actus")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplaySpeed: 1500,
      respondTo: "slider",
      responsive: true,
      arrows: true,
      dots: false,
      infinite: true,
      centerPadding: "18px",
      prevArrow:
        '<span class="slide-arrow slide-prev fa-light fa-chevron-left"></span>',
      nextArrow:
        '<span class="slide-arrow slide-next fa-light fa-chevron-right"></span>',
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

  $(".bloc--accesrapides .links-post")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplaySpeed: 4000,
      respondTo: "slider",
      responsive: true,
      arrows: false,
      dots: false,
      infinite: true,
      centerPadding: "18px",
      responsive: [
        {
          breakpoint: 1330,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
}

function accordeon() {
  $(".bloc--accordeon .accordeon--content").hide();

  // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
  // et on remplace l'élément span qu'ils contiennent par un lien :
  $(".accordeon .accordeon--title").on("click", function () {
    // Si le sous-menu était déjà ouvert, on le referme :
    if ($(this).next(".accordeon--content:visible").length != 0) {
      $(this).next(".accordeon--content").slideUp("normal");
      $(this).removeClass("open");
    }
    // Si le sous-menu est caché, on ferme les autres et on l'affiche :
    else {
      $(".accordeon .accordeon--content").slideUp("normal");
      $(".accordeon .accordeon--title").removeClass("open");
      $(this).next(".accordeon--content").slideDown("normal");
      $(this).addClass("open");
    }
    // On empêche le navigateur de suivre le lien :
    return false;
  });
}

function animationScroll() {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
  });

  let scrollAnimFade = gsap.utils.toArray(".elem--anim-opacity");
  scrollAnimFade.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        trigger: item,
        start: "top 90%",
        end: "top 75%",
        markers: false,
        scrub: true,
      },
      opacity: 0,
      duration: 0.5,
    });
  });

  if ($(".title--anim").length != 0) {
    let scrollAnimTitle = gsap.utils.toArray(".title--anim");
    scrollAnimTitle.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top 75%",
          markers: false,
          scrub: true,
        },
        opacity: 0,
        duration: 0.5,
      });
    });
  }

  if ($(".elem--anim-top").length != 0) {
    let scrollAnimtop = gsap.utils.toArray(".elem--anim-top");
    scrollAnimtop.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top 75%",
          markers: false,
          scrub: true,
        },
        opacity: 0,
        y: 90,
        duration: 0.5,
      });
    });
  }

  if ($(".elem--anim-enter").length != 0) {
    gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
  }

  // var widthScroll = $(".list-productcat").innerWidth();

  // gsap.to(".bloc--list-productcat", {
  //     scrollTrigger:{
  //         trigger: ".bloc--list-productcat",
  //         start: "-75 87",
  //         scrub: true,
  //         pin: true,
  //         markers: true,
  //     }
  // });

  // gsap.to(".list-productcat", {
  //     scrollTrigger:{
  //         trigger: ".list-productcat",
  //         start: "top 50%",
  //         scrub: true,
  //         pin: true,
  //         markers: false,
  //     },
  //     xPercent: widthScroll,
  // });
}

function onglets() {
  var hauteurMax = 0;
  $(".tab-content").each(function () {
    if ($(this).innerHeight() >= hauteurMax) {
      hauteurMax = $(this).innerHeight();
      $(".tab-content").css("min-height", hauteurMax);
    }
  });

  $(".tabs .tab").on("click", function (e) {
    e.preventDefault();

    if (!$(this).parent("li").hasClass("active")) {
      var tab = $(this).attr("data");

      /* On suprime la class active de tous les contenus */
      $(".tab-content").removeClass("active");
      $(".tab-content").hide().delay(200);

      /* On supprime la classe pour tous les liens */
      $(".tabs li").removeClass("active");

      /* On ajoute la classe active à l'onglet qui doit être visible */
      $(tab).addClass("active");
      $(tab).show();

      /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
      $(this).parent("li").addClass("active");

      return false;
    }
  });
}

function menuImage() {
  $("#nav-main > li.menu-item").each(function () {
    $(this)
      .find("> ul.sub-menu")
      .css("width", $("#nav-main").innerWidth())
      .css(
        "left",
        -$(this).offset().left +
          $("#nav-main > li.menu-item:eq(0)").offset().left
      );
  });

  $("#nav-main > li.menu-item").each(function () {
    var imgSrc = $(this).find(" > a img").attr("src");
    $(this)
      .find("> ul.sub-menu")
      .append(
        '<div class="sub-menu-img" style="background-image: url(\'' +
          imgSrc +
          "');\"></div>"
      );
    $(this).find(" > a img").remove();
  });
}

function getParameterFilter() {
  var url = window.location.search.substring(1);
  var varUrl = url.split("&");
  for (var i = 0; i < varUrl.length; i++) {
    var parameter = varUrl[i].split("=");
    console.log(parameter[0]);
    if (
      parameter[0] == "filter_couleur" ||
      parameter[0] == "filter_cepages" ||
      parameter[0] == "filter_millesime"
    ) {
      $(".products.columns-1").addClass("products-filter");
    }
  }
}

//gestion du template produit variable
